@media print {
	/* hide top nav bar and print button */
	nav.navbar, #print-btn, #get-help-btn, footer {
		display: none;
	}
	
	/* extra padding to vertically center-ish */
	#course-name {
		margin-top: 150px;
	}
	
	/* full page width */
	#container {
		width: 100%;
		max-width: 100%;
	}
}